.success-response {
    position: fixed;
    top: 0.6rem;
    background-color: #69C061;
    color: white;
    height: 54.78px;
    width: 100%;
    border: 1px solid #69C061;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    left:0;
}

.error-response {
    position: fixed;
    top: 0.6rem;
    background-color: #E10808;
    color: white;
    height: 54.78px;
    width: 100%;
    border: 1px solid #E10808;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    left:0;
}

.default-response {
    position: absolute;
    background-color: white;
    color: white;
    height: 54.78px;
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message {
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
}