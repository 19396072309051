.pagination-arrow {
	position: relative;
	text-align: left;
}
.pagination-arrow:before,
.pagination-arrow:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.pagination-arrow,
.pagination-arrow:before,
.pagination-arrow:after {
	width:  1rem;
	height: 1rem;
	border-top-right-radius: 30%;
}

.pagination-arrow {
	transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
}
.pagination-arrow:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.pagination-arrow:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.pagination-arrow-prev {
  transform: rotate(-90deg);
}
.pagination-arrow-next {
  transform: rotate(90deg);
}
